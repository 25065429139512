import * as React from 'react';
import styled from 'styled-components';
import { SpokePageSpinner } from '@doltech/core/lib/auth/SpokePageSpinner';

const EmptyPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageLoader = () => (
  <EmptyPage>
    <SpokePageSpinner />
  </EmptyPage>
);
